<div class="container-box">
  <nz-tabset nzHideAdd nzType="editable-card" nzSize="small" style="height:100%;" nzTabPosition="left"
    [(nzSelectedIndex)]="selectedTab" (nzClose)="onTabClose($event)" (nzSelectChange)="onTabChange($event)">
    <nz-tab *ngFor="let tab of tabs" nzSize="small" [nzTitle]="tab.name" [nzClosable]="tab.closable">
      <!-- <ng-template nz-tab> -->
        <div [ngSwitch]="tab.type">
          <pod-search-form *ngSwitchCase="'search'"></pod-search-form>
          <pod-search-ai-form *ngSwitchCase="'search-ai'"></pod-search-ai-form>
          <pod-order-detail-form *ngSwitchCase="'order-detail'" [data]="tab.data"></pod-order-detail-form>
          <div *ngSwitchDefault>Error Type</div>
        </div>
      <!-- </ng-template> -->
    </nz-tab>
  </nz-tabset>

  <div class="actions">
    <button nz-button class="item" (click)="onClickAction('toggleWidth')" nz-tooltip nzTooltipTitle="Resize Form"
      nzTooltipPlacement="left">
      <span nz-icon [nzType]="'swap'" nzTheme="outline"></span>
    </button>
    <button nz-button class="item" (click)="onClickAction('closeAllTabs')" nz-tooltip nzTooltipTitle="Close All Tabs"
      nzTooltipPlacement="left">
      <span nz-icon nzType="close-circle" nzTheme="outline"></span>
    </button>
  </div>
</div>