<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div class="select-all bottom10">
  <button nz-button nzType="default" nzTheme="outlet" (click)="onSelectAll()">Select All</button>
</div>
<div class="content-box bottom20">
  <div *ngFor="let item of listEmailItem; let index = index" class="flex bottom10">
    <input type="checkbox" [checked]="isItemSelected(index)" (change)="onSelectItemChange(index, $event)">
    <div class="left10">
      {{getLabelEmailItem(item)}}
    </div>
  </div>
</div>

<div form-footer [onProgress]="onProgress"
  [canClickOK]="listIndexSelected.length > 0" [canClickCancel]="!onProgress" labelOK="Send"
  (onOK)="onBtnSave()" (onCancel)="closeDialog()"></div>