<div>
  <nz-table #nzTable [nzData]="data" [nzFrontPagination]="false">
    <thead>
      <tr>
        <th>Receiver</th>
        <th>Time</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of nzTable.data">
        <td>
          <div *ngFor="let receiver of item?.receivers || []">{{receiver}}</div>
        </td>
        <td>{{getTimeRequest(item)}}</td>
      </tr>
    </tbody>
  </nz-table>
</div>
