<div class="form-header no-border no-padding-bottom bottom20">
  <div class="flex-space-between">
    <div class="form-title f18b">Export Linehaul Payment</div>
    <div>
      <i (click)="closeDialog()" class="ic-close clickable" nz-icon nzTheme="outline" nzType="close"></i>
    </div>
  </div>
</div>
<div>
  <nz-table 
      #nzTable nzBordered="true"
      [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading"
      [nzData]="listOfData" nzSize="small"
      [nzFrontPagination]="false"
      [nzShowPagination]="false">
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead>
        <tr>
          <th nzWidth="80px">Warp ID</th>
          <th>Saks PO#</th>
          <th>Origin Zipcode</th>
          <th>Miles</th>
          <th>Total Weight</th>
          <th>Pallets</th>
          <th>Pallets Import</th>
          <th>NYC Accessorial</th>
          <th>Local Pickup + Xdock $</th>
          <th>Local Pickup Min</th>
          <th>Per Pallet Linehaul</th>
          <th>Final Rate</th>
          <th nzWidth="200px">Invoice Validation</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of nzTable.data; let i = index">
          <td>
            <span *ngIf="!item.orderUrl">{{item.warpId}}</span>
            <a *ngIf="item.orderUrl" target="_blank" [routerLink]="item.orderUrl">{{item.warpId}}</a>
          </td>
          <td>{{item.POs}}</td>
          <td>{{item.originZipcode}}</td>
          <td>{{item.miles}}</td>
          <td>{{item.totalWeight}}</td>
          <td [ngClass]="{'valid': item.isValidNumOfpallets === true, 'invalid': item.isValidNumOfpallets === false }">
            {{item.pallets}}
          </td>
          <td [ngClass]="{'valid': item.isValidNumOfpallets === true, 'invalid': item.isValidNumOfpallets === false }">
            {{item.numOfPalletsImport}}
          </td>
          <td>{{item.nycAccessorial}}</td>
          <td>{{item.localPickup}}</td>
          <td>{{item.localPickupMin}}</td>
          <td>{{item.perPalletLinehaul}}</td>
          <td>{{item.finalRate}}</td>
          <td>
            <div class="center-children f20">
              <div *ngIf="item.isValidGenInvoice?.isValid">
                <div><span nz-icon [nzType]="'check-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'#52c41a'"></span></div>
                <div *ngIf="item.isValidGenInvoice?.hasInvoice" class="f14">
                  <div>Invoice has been created</div>
                </div>
              </div>
              <div *ngIf="!item.isValidGenInvoice?.isValid">
                <div><span nz-icon [nzType]="'close-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'red'"></span></div>
                <div style="color: red; font-size: 14px;">{{item.isValidGenInvoice?.msg}}</div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
</div>
<div class="separator h top30"></div>
<div class="top20 flex" style="flex-direction: row-reverse;">
  <button nz-button 
    [disabled]="!isValidPallets || !isValidInvoice || isDownloadingInvoice" 
    [nzLoading]="isDownloadingInvoice"
    (click)="onBtnGenerateInvoice()" 
    nzType="primary">{{ labelBtnInvoice }}
  </button>

  <button nz-button class="right20"
    [disabled]="!isValidPallets || isDownloadingPallets" 
    [nzLoading]="isDownloadingPallets"
    (click)="onBtnDownloadExcel()" 
    nzType="primary">Download Excel
  </button>

  <button nz-button class="right20"
    [disabled]="isDownloadingPallets || isDownloadingInvoice"
    (click)="onBtnReCheck()" 
    nzType="default">Re-Check
  </button>
</div>