import { Component } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { QuickUploadPodService } from "../../quickUploadPod.service";

@Component({
  selector: "pod-search-ai-form",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class PodSearchAIForm extends BaseComponent {
  constructor(
    private quickUploadPodService: QuickUploadPodService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    // this.subscription.add(this.quickUploadPodService.imageSelectedIndex.subscribe({
    //   next: index => {
    //     this.selectedImage = this.quickUploadPodService.getSelectedImage();
    //   }
    // }));
  }


}
