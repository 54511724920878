import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { BaseDialog } from "@dialogs/base-dlg";
import { Observable } from "rxjs";
import { DialogService } from "@dialogs/dialog.service";
import { DownloadingComponent } from "@app/admin/components/downloading/downloading.component";
import { InputHelper } from "@services/input-helper";
import { Utils } from "@services/utils";
import { DateUtil } from "@services/date-utils";

@Component({
  selector: '[export-customer-payment-confirm]',
  templateUrl: './index.html',
  styleUrls: ['./style.scss']
})
export class ExportCustomerPaymentConfirm extends BaseDialog {

  selectedTabIndex = 0;
  keys = {
    active: 'active',
    podProblem: 'podProblem',
  }
  tabs = [
    {
      name: 'Active',
      key: this.keys.active,
      total: 0,
    },
    {
      name: 'POD Problem',
      key: this.keys.podProblem,
      total: 0,
    },
  ]
  listOfDataActive: any[] = [];
  listOfDataPODProblem: any[] = [];
  numOfOrderHasInvoice = 0;

  public limit = 10;
  public pageIndex: number = 1;
  public isLoading: boolean = false;
  public isDownloadingInvoice: boolean = false;
  private customerName = '';

  @Input() onRetry: () => Observable<any>;
  @Input() onExport: () => Observable<any>;
  @Input() filter;

  @Input() set listItems(value) {
    this.processListItems(value);
  }

  private processListItems(orders) {
    for (let order of orders || []) {
      let customerName = order?.clients?.[0]?.name;
      if (!this.customerName && customerName) {
        this.customerName = String(customerName).replace(' ', '_');
      }
      order.customerName = customerName || 'N/A';
      order.warpId = this.showOrderWarpId(order.warpId);
      order.amount = InputHelper.formatMoney2(order.amount?.toString());
      if (order?.invoice?.insert?.when) {
        order.invoiceCreateWhen = DateUtil.format(order.invoice.insert.when, 'MMM D, YYYY, h:mm a');
      }
    }
    let listOfDataActive: any[] = [];
    let listOfDataPODProblem: any[] = [];
    let numOfOrderHasInvoice = 0;
    for (let order of orders || []) {
      if (order?.isValidGenInvoice?.isValid) {
        if (order?.invoiceFileId) numOfOrderHasInvoice += 1;
        listOfDataActive.push(order);
      } else {
        listOfDataPODProblem.push(order);
      }
    }
    this.listOfDataActive = listOfDataActive;
    this.listOfDataPODProblem = listOfDataPODProblem;
    this.numOfOrderHasInvoice = numOfOrderHasInvoice;
    this.tabs.find(it => it.key == this.keys.active).total = listOfDataActive.length;
    this.tabs.find(it => it.key == this.keys.podProblem).total = listOfDataPODProblem.length;
  }

  countPOD(item) {
    const shipments = item?.shipments || [];
    let count = 0;
    for (let shipment of shipments) {
      count += shipment?.pod?.total ?? 0;
    }
    return count;
  }

  countConfirmedPOD(item) {
    const shipments = item?.shipments || [];
    let countNotConfirmed = 0;
    for (let shipment of shipments) {
      countNotConfirmed += shipment?.pod?.notConfirmed ?? 0;
    }
    return this.countPOD(item) - countNotConfirmed;
  }

  isConfirmedPOD(item) {
    const shipments = item?.shipments || [];
    let confirmed = true;
    for (let shipment of shipments) {
      const pod = shipment.pod || {}
      if (pod?.notConfirmed) {
        confirmed = false;
        break;
      }
    }
    return confirmed;
  }

  getOrderLink(order) {
    return `${Const.routeAdminOrderList}?page=1&search=${order.warpId}`
  }

  get canGenerateInvoice(): boolean {
    return !!this.listOfDataActive?.length;
  }

  loadingRef;
  onBtnExport() {
    this.isDownloadingInvoice = true;
    this.loadingRef = DialogService.openFormDialog1(DownloadingComponent, {});
    this.onExport().subscribe(
      resp => {
        let blob = new Blob([resp], { type: "application/zip" });
        let url = URL.createObjectURL(blob);
        let toDate = this.filter?.toDate ?? Date.now();
        let fileName = `${this.customerName}_Invoice_${DateUtil.format(toDate, 'YYMMDD-hhmmss')}.zip`;
        Utils.downloadFile(url, fileName);
        this.isDownloadingInvoice = false;
        this.loadingRef?.close();
        this.onBtnReCheck();
      }, err => {
        this.showErr(err);
        this.isDownloadingInvoice = false;
        this.loadingRef?.close();
      }
    );
  }

  onBtnReCheck() {
    this.isLoading = true;
    this.onRetry().subscribe(
      resp => {
        this.processListItems(resp?.data?.list_data ?? []);
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }
    
}
