import { Component } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { QuickUploadPodService } from "../../quickUploadPod.service";
import { DeliveryInfo, Order, Shipment } from "@wearewarp/types/data-model";
import { WarpId } from "@wearewarp/universal-libs";
import { DateUtil } from "@services/date-utils";
import { Const } from "@const/Const";
import { OrderDisplayInfo } from "../../quickUploadPod.interface";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "pod-search-form",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class PodSearchForm extends BaseComponent {
  constructor(
    private quickUploadPodService: QuickUploadPodService,
  ) {
    super();
  }


  public loading: boolean = false;
  public orders: OrderDisplayInfo[] = [];
  public expandSet = new Set<string>();
  public searchKeyword: string;
  public searchText$ = new Subject<string>();

  ngOnInit(): void {
    super.ngOnInit();
    console.log("vao...")
    this.subscription.add(this.quickUploadPodService.searchText.subscribe({
      next: keyword => {
        this.searchKeyword = keyword;
        this.onSearch(keyword)
      }
    }));
    this.searchText$.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(value => {
      this.onSearch(value);
    });
  }

  onViewOrderDetail(order) {
    this.quickUploadPodService.openOrderDetail(order);
  }

  async onSearchKeyWordChange(event) {
    //if enter key then search
    if (event.keyCode == 13) {
      this.onSearch(this.searchKeyword);
    }

  }

  async onSearch(keyword) {
    this.quickUploadPodService.updateSearchText(keyword);
    //Call API
    this.loading = true;
    const orders = await this.quickUploadPodService.getShipments({
      keyword
    });
    this.orders = orders.map(order => this.buildDisplayInfo(order));
    this.loading = false;
  }

  private buildDisplayInfo(order: Order): OrderDisplayInfo {
    const shipments = order?.metadata?.shipments || [];
    const firstPickup = this.getFirstPickup(order)
    const lastDropoff = this.getLastDropoff(order)
    return {
      orderId: order.id,
      orderWarpId: WarpId.showOrder(order.warpId),
      totalPOD: this.countPOD(order),
      confirmedPOD: this.countConfirmedPOD(order),
      shipmentIds: order.shipmentIds,
      shipmentWarpIds: shipments.map(s => WarpId.showShipment(s.warpId)),
      refNums: shipments.reduce((shipmentRefs, s) => {
        const deliveryInfos = s.deliveryInfos || [];
        const refNums = deliveryInfos.reduce((refs, deliveryInfo) => {
          return refs.concat(deliveryInfo.refNums || [])
        }, [])
        return shipmentRefs.concat(refNums)
      }, []),
      firstPickup: {
        address: this.getAddressText(firstPickup.addr),
        locationName: firstPickup.locationName,
        time: DateUtil.displayLocalTime(
          firstPickup.windows?.[0]?.from || firstPickup?.appointmentInfo?.from,
          {
            format: Const.FORMAT_GUI_DATETIME_V5,
            timezone: firstPickup?.addr?.metadata?.timeZoneStandard

          })
      },
      lastDropoff: {
        address: this.getAddressText(lastDropoff.addr),
        locationName: lastDropoff.locationName,
        time: DateUtil.displayLocalTime(
          lastDropoff.windows?.[0]?.to || lastDropoff?.appointmentInfo?.to,
          {
            format: Const.FORMAT_GUI_DATETIME_V5,
            timezone: lastDropoff?.addr?.metadata?.timeZoneStandard

          })
      },

    }
  }

  private getFirstPickup(order: Order): DeliveryInfo {
    const sortedTasks = order.sortedTaskIds;
    const shipments = order.metadata?.shipments || [];

    if (!sortedTasks?.length) {
      const shipment = shipments[0];
      return shipment?.deliveryInfos.find(di => di.type === Const.TaskType.PICKUP);
    }

    const firstTask = sortedTasks[0][0];
    for (let shipment of shipments) {
      const deliveryInfos = shipment.deliveryInfos || [];
      for (let deliveryInfo of deliveryInfos) {
        if (deliveryInfo.id == firstTask) {
          return deliveryInfo;
        }
      }
    }
  }

  private getLastDropoff(order: Order): DeliveryInfo {
    const sortedTasks = order.sortedTaskIds;
    const shipments = order.metadata?.shipments || [];

    if (!sortedTasks?.length) {
      const shipment = shipments[0];
      return shipment?.deliveryInfos.find(di => di.type === Const.TaskType.DROPOFF);
    }

    const lastTask = sortedTasks[sortedTasks.length - 1][0];
    for (let shipment of shipments) {
      const deliveryInfos = shipment.deliveryInfos || [];
      for (let deliveryInfo of deliveryInfos) {
        if (deliveryInfo.id == lastTask) {
          return deliveryInfo;
        }
      }
    }
  }

  countPOD(item) {
    const shipments = item?.metadata?.shipments || [];
    let count = 0;
    for (let shipment of shipments) {
      count += shipment?.pod?.total ?? 0;
    }
    return count;
  }

  countConfirmedPOD(item) {
    const shipments = item?.metadata?.shipments || [];
    let countNotConfirmed = 0;
    for (let shipment of shipments) {
      countNotConfirmed += shipment?.pod?.notConfirmed ?? 0;
    }
    return this.countPOD(item) - countNotConfirmed;
  }

}
