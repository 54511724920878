import { Component, Input, OnInit } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";
import { Const } from "@const/Const";
import { environment } from "@env/environment";


interface EmailItem {
  loadCode: string,
  contactName: string,
  contactEmail: string,
  jobId: string
}

@Component({
  selector: '[check-list-email-dialog]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class CheckListEmailDialog extends BaseFormDialog1 implements OnInit {
  @Input() data;
  
  public isLoading = false;
  public listEmailItem: Array<EmailItem> = [];
  public listSelectedItem: Array<EmailItem> = [];
  public listIndexSelected = [];

  ngOnInit(): void {
    super.ngOnInit();
    this.preHandleData();
  }
  
  preHandleData() {
    let listRouteNeedPOD = this.data?.listRouteNeedPOD ?? [];
    for (let item of listRouteNeedPOD) {
      for (let contact of item.carrier?.basicInfo?.contacts || []) {
        let emailItem: EmailItem = {
          loadCode: item.code,
          contactName: item.carrier?.basicInfo?.name ?? '',
          contactEmail: contact.email,
          jobId: item.id
        }
        this.listEmailItem.push(emailItem);
      }
    }
    this.listIndexSelected = Array.from(this.listEmailItem.keys())
  }

  async onBtnSave() {
    this.listSelectedItem = [];
    this.listIndexSelected.forEach(index => {
      this.listSelectedItem.push(this.listEmailItem[index]);
    })
    this.startProgress();
    let url = `${environment.backendUrl}/${Const.APIV2(Const.APIURI_CARRIERS)}/request-route-pod`;
    let params = {
      message: this.data.message ?? '',
      listEmailSending: this.listSelectedItem
    }
    this.api.POST(url, params).subscribe(
      (resp) => {
        this.stopProgress();
        this.onUpdateSuccess(resp)
      },
      (err) => {
        this.showErr("Fail to send email to carrier.");
        this.stopProgress();
      }
    )
  }

  onSelectItemChange(index, event) {
    let id = this.listIndexSelected.indexOf(index);
    if (id > -1) {
      this.listIndexSelected.splice(id, 1);
    } else {
      this.listIndexSelected.push(index);
    }
  }

  isItemSelected(index) {
    return this.listIndexSelected.includes(index);
  }

  getLabelEmailItem(item: EmailItem) {
    return `${item.loadCode} - ${item.contactEmail} - ${item.contactName}`;
  }

  onSelectAll() {
    if (this.listIndexSelected.length < this.listEmailItem.length) {
      this.listIndexSelected = Array.from(this.listEmailItem.keys())
    } else {
      this.listIndexSelected = [];
    }
  }
}