<div class="container-box">
  <div class="dashboard-child-container no-padding list">
    <div class="form-message bottom20">
      <div style="padding: 5px 0;">Message<span class="label-mark-required"></span></div>
      <textarea [(ngModel)]="message" [nzAutosize]="{ minRows: 3, maxRows: 6}"
        nz-input placeholder="Enter message to email carrier..."></textarea>
      <div class="message-note top10">
        <div>Note:</div>
        <div class="message-note-content">
          <div class="accepted-variable">- Accepted variable: 
            <span *ngFor="let item of listAcceptedVariable; let index = index">{{item}}<span *ngIf="index < listAcceptedVariable.length - 1">, </span></span>
          </div>
          <div>- Please put the variable into {{'${}'}} like format to use</div>  
        </div>
      </div>
    </div>

    <div class="box-filter bottom20">
      <!-- Select carriers -->
      <div class="box-select-option">
        <div class="filter-label">Select carriers</div>
        <nz-select nzBackdrop="true" style="width: 250px;" [nzSize]="'large'"
          nzAllowClear nzShowSearch nzMode="multiple" [nzShowArrow]="true"
          [(ngModel)]="conditions.carrierIds"
          [nzPlaceHolder]="'Select'" [nzNotFoundContent]="'No data'">
          <nz-option *ngFor="let item of carrierStatistics" [nzLabel]="getLabelCarrierStatistic(item)" [nzValue]="item._id"></nz-option>
        </nz-select>
      </div>

      <!-- Select clients -->
      <div class="box-select-option">
        <div class="filter-label">Select clients</div>
        <nz-select nzBackdrop="true" style="width: 250px;" [nzSize]="'large'"
          nzAllowClear nzShowSearch nzMode="multiple" [nzShowArrow]="true" 
          [(ngModel)]="conditions.clientIds"
          [nzPlaceHolder]="'Select'" [nzNotFoundContent]="'No data'">
          <nz-option *ngFor="let item of listClients" [nzLabel]="item?.name" [nzValue]="item?.id"></nz-option>
        </nz-select>
      </div>
      
      <!-- Pickup date from-->
      <div class="box-select-option">
        <div class="filter-label">From Date</div>
        <nz-input-group nzAddOnAfter="EST">
          <nz-date-picker style="width: 200px" [nzSize]="'large'" nzFormat="yyyy-MM-dd" nzPlaceHolder="Chose Date" [(ngModel)]="conditions.pickFrom">
          </nz-date-picker>
        </nz-input-group>
      </div>

      <!-- Pickup date to -->
      <div class="box-select-option">
        <div class="filter-label">To Date</div>
        <nz-input-group nzAddOnAfter="EST">
          <nz-date-picker style="width: 200px" [nzSize]="'large'" nzFormat="yyyy-MM-dd" nzPlaceHolder="Chose Date" [(ngModel)]="conditions.pickTo">
          </nz-date-picker>
        </nz-input-group>
      </div>

      <!-- Sending status -->
      <div class="box-select-option">
        <div class="filter-label">Sending status</div>
        <nz-select nzBackdrop="true" style="width: 250px;" [nzSize]="'large'"
          nzAllowClear nzShowSearch
          [nzPlaceHolder]="'Select'"
          [(ngModel)]="conditions.sendingStatus">
          <nz-option [nzLabel]="'Sent to the carrier'" [nzValue]="true"></nz-option>
          <nz-option [nzLabel]="'Never Sent'" [nzValue]="false"></nz-option>
        </nz-select>
      </div>

      <!-- Select except clients -->
      <div class="box-select-option">
        <div class="filter-label">Except clients</div>
        <nz-select nzBackdrop="true" style="width: 250px; overflow: hidden;" [nzSize]="'large'"
          nzAllowClear nzShowSearch nzMode="multiple" [nzShowArrow]="true" 
          [(ngModel)]="conditions.exceptClientIds"
          [nzPlaceHolder]="'Select'" [nzNotFoundContent]="'No data'">
          <nz-option *ngFor="let item of listClients" [nzLabel]="item?.name" [nzValue]="item?.id"></nz-option>
        </nz-select>
      </div>
    </div>

    <div class="action-container bottom20">
      <div>
        <button nz-button nzType="default" [nzSize]="'large'" (click)="onGetData()">
          <span nz-icon nzType="reload" nzTheme="outline"></span>Get Filter
        </button>
      </div>
      <div *ngIf="getSelectedItems().length" style="margin-left: 20px;">
        <button nz-button nzType="default" [nzSize]="'large'" (click)="handleSendingEmail()">
          <span nz-icon nzType="send" nzTheme="outline"></span> Send email</button>
      </div>

      <div class="summary-container">
        <span *ngIf="getSelectedItems().length">
          <i>Selected: {{displaySummaryRecords(getSelectedItems().length)}}</i>
          |
        </span>
        <ng-container *ngTemplateOutlet="tplSummary"></ng-container>
      </div>
    </div>

    <ng-template #tplSummary>
      <span style="color: red">
        <i>Total: {{displaySummaryRecords(summary.totalRecords)}}</i>
      </span>
    </ng-template>

    <div class="list-body">
      <!-- <cdk-virtual-scroll-viewport *ngIf="!isLoading" itemSize="64" minBufferPx="256" maxBufferPx="256" class="cdk-virtual-scroll-element"> -->
      <nz-table #nzTable nzBordered="true"
        [nzData]="listData"
        [nzLoadingIndicator]="tplLoading" [nzLoading]="isLoading" [nzNoResult]="tplNoData"
        [nzScroll]="{x: '1200px', y: '600px'}"
        [nzPageSize]="20"
        (nzCurrentPageDataChange)="currentPageDataChange($event)">
        <thead (nzSortOrderChange)="onSortOrderChange($event)">
          <tr>
            <th nzWidth="45px"
              [nzChecked]="selectAll" [nzIndeterminate]="indeterminate"
              (nzCheckedChange)="onAllSelected($event)"
            ></th>
            <th nzWidth="140px">Load</th>
            <th nzWidth="160px" nzSortOrder="null" [nzSortFn]="true" nzColumnKey="clients.0.name">Customer</th>
            <th nzWidth="160px">Status</th>
            <th nzWidth="140px" nzSortOrder="null" [nzSortFn]="true" nzColumnKey="pickDt.time">Pickup Date/Time</th>
            <th nzSortOrder="null" [nzSortFn]="true" nzColumnKey="carrier.basicInfo.name">Carrier Name</th>
            <th>Contacts</th>
            <th nzWidth="200px">Last Sent</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of nzTable.data">
            <td (nzCheckedChange)="onItemSelected(item)" [nzChecked]="!!setOfSelectedId[item.id]" (click)="stopPropergate($event)"></td>
            <td>
              <div><a [href]="goToDispatch(item)" target="_blank">{{ item.code }}</a></div>
              <div class="flex top5" style="width:130px; display:block; word-wrap: break-word; font-size: 12px;">
                (<span *ngFor="let shipment of item.shipments; let index = index">{{ showShipmentWarpId(shipment) }}<span *ngIf="index < item.shipments?.length - 1">, </span>
                </span>)
              </div>
            </td>
            <td>
              <div *ngFor="let client of item.clients; let index = index">
                {{ client.name }}{{ index < item.clients?.length - 1 ? ',' : ''}}
              </div>
            </td>
            <td class="ant-table-cell text-center">
              <div class="{{ 'status-' + item.status }}" [ngClass]="{'order-status': true}">
                {{item.status}}
              </div>
            </td>
            <td>
              {{getPickupDate(item)}}
            </td>
            <td>{{item?.carrier?.basicInfo?.name}}</td>
            <td>
              <div *ngFor="let contact of item?.carrier?.basicInfo?.contacts">
                {{formatPhone(contact?.phone)}} - <br/>{{contact?.email || 'N/A'}}
              </div>
            </td>
            <td>
              <div style="text-align: center;">
                <div>{{getLastSentRequest(item)}}</div>
                <div *ngIf="countHistoryRequest(item) > 0" class="top10"><button nz-button (click)="showDetailHistory(item)">Show detail ({{countHistoryRequest(item)}})</button></div>
              </div>
            </td>
          </tr>
        </tbody>
      </nz-table>
    </div>
  </div>
  <ng-template #tplLoading>
    <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
  </ng-template>
  <ng-template #tplNoData>
    <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
  </ng-template>
</div>