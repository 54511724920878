<div class="container-box" [ngClass]="{fullscreen: viewImageFullScreen}">
  <pan-zoom [config]="panZoomConfig" class="pod-panzoom {{!selectedImage? 'display-none': ''}}">
    <div class="container-content">
      <text-detection class="attached-pod" *ngIf="selectedImage" [imgUrl]="selectedImage.url | safeUrl"
        [model]="getExtractedData()" (onWordClick)="onSelectedWord($event)"></text-detection>
    </div>
  </pan-zoom>
  <!-- actions -->
  <div class="actions" *ngIf="selectedImage">
    <button nz-button class="item" (click)="onClickAction('fullscreen')" nz-tooltip nzTooltipTitle="View Fullscreen"
      nzTooltipPlacement="left">
      <span nz-icon [nzType]="viewImageFullScreen? 'shrink':'arrows-alt'" nzTheme="outline"></span>
    </button>
    <button nz-button class="item" (click)="onClickAction('toggleGroup')" nz-tooltip
      [nzTooltipTitle]="selectedImage.groups?.length > 0? 'Remove from group':'Add to group'" nzTooltipPlacement="left">
      <span nz-icon [nzType]="selectedImage.groups?.length > 0 ? 'menu-fold':'menu-unfold'" nzTheme="outline"></span>
    </button>
    <button nz-button class="item" (click)="onClickAction('download')" nz-tooltip nzTooltipTitle="Download"
      nzTooltipPlacement="left">
      <span nz-icon nzType="cloud-download" nzTheme="outline"></span>
    </button>
    <button nz-button class="item" (click)="onClickAction('analyzePhoto')" nz-tooltip nzTooltipTitle="Analyze Photo"
      nzTooltipPlacement="left" [nzLoading]="selectedImage?.analyzing">
      <span nz-icon nzType="monitor" nzTheme="outline"></span>
    </button>
    <button nz-button class="item" (click)="onClickAction('remove')" nz-tooltip nzTooltipTitle="Remove"
      nzTooltipPlacement="left">
      <span nz-icon nzType="delete" nzTheme="outline" style="color: red;"></span>
    </button>
  </div>

  <div class="loading-detection-data" *ngIf="selectedImage?.analyzing">
    analyzing photos....
  </div>
  <div class="assigned-shipments" *ngIf="selectedImage?.addedToShipments">
    <nz-tag *ngFor="let shipment of selectedImage?.addedToShipments" nzColor="success" nzSize="small">
      {{showShipmentWarpId(shipment)}}
    </nz-tag>
  </div>

  <div class="no-data-box" *ngIf="!selectedImage">
    <img class="no-data" src="/assets/img/nodata.svg" alt="" />
    <div class="">Please upload file first.</div>
  </div>
</div>