<div class="container-box">
  <div class="box" style="max-width: 50%;">
    <div class="box-header">
      <div class="box-title">Driver Payment</div>
      <div class="box-tools">
        <ic-driver-report></ic-driver-report>
      </div>
    </div>
  </div>
  <div class="box" style="max-width: 50%;">
    <div class="box-header">
      <div class="box-title">Linehaul Payment</div>
      <div class="box-tools">
        <ic-linehaul-report></ic-linehaul-report>
      </div>
    </div>
  </div>
</div>

<div class="container-box top20">
  <div class="box" style="max-width: 50%;">
    <div class="box-header">
      <div class="box-title">Customer Payment</div>
      <div class="box-tools">
        <ic-customer-report></ic-customer-report>
      </div>
    </div>
  </div>
  <div class="flex1" style="max-width: 50%;">&nbsp;</div>
</div>