import { Component } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
import { environment } from "@env/environment";
import { DateUtil } from "@services/date-utils";
import moment from "moment";

@Component({
  selector: "ic-driver-report",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class IcDriverPayment extends BaseComponent {
  constructor(
  ) {
    super();
  }

  public filter = {
    clientIds: ['01H22NK3MBXBDCW6AZDWFZ09V0'], //walmart
    fromDate: moment().subtract(1,'week').toDate(),
    toDate: moment().toDate()
  }
  public loading = false;

  public onExport() {
    console.log('export', this.filter);
    this.loading = true;
    const fromDate = DateUtil.toBeginOfDay(this.filter.fromDate);
    const toDate = DateUtil.toEndOfDay(this.filter.toDate);

    const params = {
      filter: JSON.stringify({
        clientId: this.filter.clientIds,
        fromDate: DateUtil.convertLocalTime(fromDate, 'America/New_York')?.toISOString(),
        fromDateEnd: DateUtil.convertLocalTime(toDate, 'America/New_York')?.toISOString() //sử dụng field này để lấy ngày theo pickupFrom
      })
    }

    let url = `${environment.backendUrl}/${Const.APIV2(Const.APIURI_FINANCES)}/reports/ic-driver-payment`;
    url = Utils.appendQueryStringIntoUrl(url, params);
    console.log('url', url);
    this.api.download(url).subscribe(
      (resp) => {
        let blob = new Blob([resp], { type: "application/vnd.ms-excel" });
        let url = URL.createObjectURL(blob);
        let fileName = `ic-driver-payment-${moment(this.filter.fromDate).format("YYYY-MMMDD")}-${moment(this.filter.toDate).format("MMMDD")}.xlsx`;
        Utils.downloadFile(url, fileName);
        this.loading = false;
      },
      (err) => {
        this.showErr(err);
      }
    );
  }

}
