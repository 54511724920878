import { BaseList } from "@app/admin/base/list";
import { Component, EventEmitter, HostListener } from "@angular/core";
import { Const } from "@const/Const";
import { Utils } from '@services/utils';
import { environment } from "@env/environment";
import { InputHelper } from '@services/input-helper';
import { DialogService } from "@dialogs/dialog.service";
import { CheckListEmailDialog } from "./check-list-email-dialog";
import { DeliveryInfo } from "@wearewarp/types/data-model";
import { DateUtil } from "@services/date-utils";
import { TopbarTitle } from "@app/admin/shared/TopbarTitle";
import { HistoryRequestPod } from "./history-request-pod";
@Component({
  selector: 'assign-carriers-provide-pod',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class AssignCarriersProvidePod extends BaseList implements TopbarTitle {
  public carrierStatistics = [];
  public listClients = [];
  public displayData = [];
  public conditions: any = {
    carrierIds: [],
    clientIds: [],
    exceptClientIds: ['01H22NK3MBXBDCW6AZDWFZ09V0', '01H22NK3MAKRBVKRPQDDK3XF01'],  //walmart, warp cross dock - vernon CA
    pickFrom: null,
    pickTo: null,
    sentStatus: null
  };
  public message = this.getDefaultMessage();
  public listAcceptedVariable = ['contactName', 'loadCode'];
  protected defaultFilter = {
    'podStatus': 'need_pod'
  };

  public selectAll = false;
  public indeterminate = false;
  public setOfSelectedId: any = {};
  public summary: any = {
    totalRecords: 0,
    records: 0
  }
  
  constructor() {
    super();
    this.apiVersion = 'v2';
  }

  subscribeTopbarTitle(fn: Function) {
    return fn(`Request carrier to provide POD`);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getCarrierStatistics();
  }

  protected getApiUrl(): string {
    return `${Const.APIURI_JOBS}/get/routes-by-pod-status`;
  }

  // Set filter default to get list order that need pod
  protected prepareParamGetList() {
    let params: any = {};
    // Lấy toàn bộ record và dùng cdk virtual croll để hiển thị.
    params.limit = 0;

    let filter = {
      podStatus: 'need_pod',
      carrierIds: this.conditions.carrierIds ?? [],
      clientIds: this.conditions.clientIds ?? [],
      exceptClientIds: this.conditions.exceptClientIds ?? [],
      pickFrom: null,
      pickTo: null,
      sentStatus: this.conditions.sendingStatus ?? null
    }
    if (this.conditions.pickFrom) {
      const pickFrom = DateUtil.toBeginOfDay(this.conditions.pickFrom);
      filter.pickFrom = DateUtil.convertLocalTime(pickFrom, 'America/New_York')?.toISOString();
    }

    if (this.conditions.pickTo) {
      const pickTo = DateUtil.toEndOfDay(this.conditions.pickTo);
      filter.pickTo = DateUtil.convertLocalTime(pickTo, 'America/New_York')?.toISOString();
    }
    params.filter = JSON.stringify(filter);
    
    return params;
  }

  protected onGetDataSucceeded(resp) {
    this.onReloadSummary();
    this.resetListSelected();
  }

  refreshSelectedAll() {
    let total = this.displayData.length;
    let selected = this.displayData.filter(it => !!this.setOfSelectedId[it.id]).length;
    this.selectAll = total > 0 && selected == total;
    this.indeterminate = selected > 0 && !this.selectAll;
  }

  getSelectedItems() {
    return Object.values(this.setOfSelectedId) || []
  }

  onAllSelected(event: any) {
    if (!event) {
      for (let item of this.displayData) {
        delete this.setOfSelectedId[item.id];
      }
    } else {
      for (let item of this.displayData) {
        this.setOfSelectedId[item.id] = item;
      }
    }
    this.selectAll = !this.selectAll;
  }

  onItemSelected(row: any) {
    if (!row) return;
    if (this.setOfSelectedId[row.id]) {
      delete this.setOfSelectedId[row.id]
    } else {
      this.setOfSelectedId[row.id] = row
    }
    this.refreshSelectedAll()
  }
  
  stopPropergate(event) {
    event.stopPropagation();
  }

  onDataListPageChanged(page) {
    super.onDataListPageChanged(page);
    this.onReloadSummary();
    this.resetListSelected();
  }

  resetListSelected() {
    this.setOfSelectedId = {};
    this.selectAll = false;
  }

  onReloadSummary() {
    this.summary.totalRecords = this.totalCount;
    this.summary.records = this.listData.length;
  }

  async handleSendingEmail() {
    let checkErr: boolean = this.onValidateMsgVariable(this.message);
    if (!checkErr) return;
    let params = await this.getRequestParams();
    DialogService.openFormDialog1(CheckListEmailDialog, {
      nzComponentParams: {
        updateSuccess: (resp) => {
          this.showSuccess('Send email to carriers success.');
          this.onBtnRefresh();
        },
        data: params,
        closeOnSuccess: true,
      },
      nzClassName: 'model-no-padding',
      nzCentered: true
    })
  }

  async getRequestParams() {
    let listRouteNeedPOD = this.getSelectedItems(); 
    let params = {
      listRouteNeedPOD: listRouteNeedPOD,
      message: this.message
    }
    return params;
  }

  public displaySummaryRecords(count: any): string {
    count = count || 0;
    let strPallets = count > 1 ? "routes" : "route";
    return `${count} ${strPallets}`
  }

  getCarrierStatistics() {
    let url = `${environment.backendUrl}/${Const.APIV2(Const.APIURI_CARRIERS)}/statistics-carrier-need-provide-pod`;
    this.api.GET(url).subscribe(
      (resp) => {
        this.carrierStatistics = resp.data?.list_data ?? [];
        this.onStatisticClientList(this.carrierStatistics);
      },
      (err) => {
        this.showErr(err);
      }
    )
  }

  onStatisticClientList(data) {
    this.listClients = [];
    let existedArr = [];
    for (let item of data) {
      if (!item.clients) continue;
      let flatClientsArr = item.clients.flat();
      for (let client of flatClientsArr) {
        if (!existedArr.includes(client.id)) {
          existedArr.push(client.id);
          this.listClients.push(client)
        }
      }
    }
  }

  onGetData() {
    this.resetListSelected();
    this.getData();
  }

  getLabelCarrierStatistic(item) {
    let carrierName = item?.carrier?.basicInfo?.name || '';
    let count = item?.count || 0;
    return count ? `(${count}) ${carrierName}` : `${carrierName}`;
  }

  goToDispatch(item) {
    const jobId = item.id ?? '';
    return `${Const.routeAdminDispatchList}/${jobId}`;
  }

  formatPhone(phone) {
    if(!phone) return 'N/A';
    return InputHelper.formatPhone(phone);
  }

  getDefaultMessage() {
    let defaultMsg = 'Dear ${ contactName },\nPlease send the PODs and invoice(s) for the Route ${ loadCode } to process your payment.\nThank you,';
    return defaultMsg;
  }

  onValidateMsgVariable(str: string): boolean {
    let ok: boolean = true;
    let errVar: string = '';
    let msg = str.replace(/\$\{.+?\}/g, (match) => {
      let len = match.length;
      let fieldName = match.slice(2, len - 1).trim();
      if (!this.listAcceptedVariable.includes(fieldName)) {
        ok = false;
        errVar = fieldName;
      }
      return '';
    })
    if (!ok) {
      this.showErr(`Not accept '${errVar}' variable`)
      return false;
    }
    return true;
  }

  getPickupDate(item) {
    let pickTimeInfo = item?.pickDt ?? '';
    return this.getDeliveryTime(pickTimeInfo);
  }

  getDeliveryTime(timeInfo) {
    if (!timeInfo) return '';
    let format = Const.FORMAT_GUI_DATETIME;
    let time = timeInfo?.time ?? '';
    let timezone = timeInfo?.timezone ?? '';
    let timeDisplay = DateUtil.displayLocalTime(time, {timezone, format});
    return timeDisplay;
  }

  currentPageDataChange(event): void {
    this.displayData = event;
    this.refreshSelectedAll();
    // this.resetListSelected();
  }

  getLastSentRequest(item) {
    let historyArr = item?.historySendRequestPOD ?? [];
    if (Utils.isArrayNotEmpty(historyArr)) {
      let lastTime = historyArr[historyArr.length - 1]?.when ?? '';
       return DateUtil.format(lastTime, Const.FORMAT_GUI_DATETIME)
    }
  }

  countHistoryRequest(item) {
    let historyArr = item?.historySendRequestPOD ?? [];
    return historyArr.length;
  }

  showDetailHistory(item) {
    let historyArr = item?.historySendRequestPOD ?? [];
    this.modalService.create({
      nzTitle: `History Request POD for the Route ${item?.code}`,
      nzContent: HistoryRequestPod,
      nzWidth: "600px",
      nzComponentParams: {
        data: historyArr
      }
    })
  }
}