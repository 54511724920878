import { BaseList } from "../../base/list";
import { Component, SecurityContext } from "@angular/core";
import { Const } from "@app/const/Const";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from '@services/api.service';
import { BaseComponent } from "@abstract/BaseComponent";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ImageLocal } from "../quickUploadPod.interface";
import { QuickUploadPodService } from "../quickUploadPod.service";
import { Subscription } from "rxjs";

@Component({
  selector: "quick-upload-pod",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class QuickUploadPod extends BaseComponent {
  constructor(
    protected activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private quickUploadPodService: QuickUploadPodService,
  ) {
    super();
  }

  public isConverting: boolean = false;
  public images: ImageLocal[] = [];
  public selectedImageIndex: number;
  public subscription: Subscription;
  public formInfoWidth: number = 50; //percent

  ngOnInit(): void {
    super.ngOnInit();
    this.autoShrinkSideBar();
    this.subscription.add(this.quickUploadPodService.images.subscribe({
      next: images => {
        this.images = images;
      }
    }));
    this.subscription.add(this.quickUploadPodService.imageSelectedIndex.subscribe({
      next: index => {
        this.selectedImageIndex = index;
      }
    }));
    this.subscription.add(this.quickUploadPodService.formInfoWidth.subscribe({
      next: value => {
        this.formInfoWidth = value;
      }
    }));
  }
  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscription.unsubscribe();
  }

  onOpenUploadForm() {
    let element: HTMLElement = document.getElementById("uploadPODInput") as HTMLElement;
    element.click();
  }

  async onFileSelected(inputElement: HTMLInputElement) {

    let files = inputElement.files;

    //reset list images
    this.quickUploadPodService.clearImages();
    //save file as blob
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let fileNameInfo = this.extractFileName(file.name);
      //image
      let images = []
      if (file.type.includes("image/")) {
        images.push(file);
      }
      else {
        //pdf
        images = await this.convertPdfToImages(file);
      }

      for (let index in images) {
        const image = images[index];
        //xử lý filename
        let fileName = `${fileNameInfo.name}_${index}.${fileNameInfo.ext}`;
        if (images.length == 1) fileName = `${fileNameInfo.name}.${fileNameInfo.ext}`;

        this.quickUploadPodService.addImage({
          blob: image,
          name: fileName,
          url: URL.createObjectURL(image),
        });
      }
    }

    inputElement.value = "";

  }

  async onSelected(index, event) {
    this.quickUploadPodService.setSelectedIndex(index);
    if (event.ctrlKey || event.metaKey) {
      this.quickUploadPodService.toggleImageToGroup(this.images[index]);
    }
  }

  async onDownloadAll() {
    for (let index = 0; index < this.images.length; index++) {
      const image = this.images[index];
      await this.quickUploadPodService.downloadImage(image);
    }
  }

  async onAnalyzeAll() {
    this.quickUploadPodService.analyzeAll();
  }

  public showShipmentWarpIds(image: ImageLocal) {
    let string = this.showShipmentWarpId(image.addedToShipments?.[0]?.warpId);
    if (image.addedToShipments?.length > 1) {
      string += ` +${image.addedToShipments?.length - 1}`
    }
    return string;
  }
  public async onDownloadGroupPhoto() {
    const images = this.quickUploadPodService.getImagesByGroup();
    if (images.length == 0) {
      this.showErr("Please select at least 1 image to download")
      return;
    }
    const pdfBlob = await this.quickUploadPodService.convertImagesToPdf(images);
    const url = window.URL.createObjectURL(pdfBlob);
    let link = document.createElement('a');
    link.href = url;
    link.download = images[0].name.replace(/\.[^/.]+$/, "") + ".pdf";
    link.target = "_blank";
    document.body.appendChild(link);
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    link.remove();
    await new Promise(resolve => setTimeout(resolve, 100));
  }

  public async onClearGroupPhoto() {
    this.quickUploadPodService.clearGroup();

  }

  private async convertPdfToImages(file: File) {
    this.isConverting = true;
    const images = await this.quickUploadPodService.convertPdfToImages(file);
    this.isConverting = false;
    return images
  }


  private extractFileName(fileName: string) {
    let ext = fileName.split('.').pop();
    const name = fileName.split('.').slice(0, -1).join('.');
    if (ext.toLowerCase() === "pdf") ext = "jpg";
    return {
      name,
      ext
    }
  }
}
