<div class="form-header no-border no-padding-bottom bottom20">
  <div class="flex-space-between">
    <div class="form-title f18b">Export Customer Payment</div>
    <div>
      <i (click)="closeDialog()" class="ic-close clickable" nz-icon nzTheme="outline" nzType="close"></i>
    </div>
  </div>
</div>
<div>
  <nz-tabset
    [(nzSelectedIndex)]="selectedTabIndex"
    [nzSize]="'small'" nzAnimated="false"
    style="width: 100%"
    type="card"
  >
    <ng-container *ngFor="let tab of tabs">
      <nz-tab [nzTitle]="titleTemplate">
        <ng-container *ngIf="tab.key == 'active'">
          <ng-container 
            [ngTemplateOutlet]="tplOrder" 
            [ngTemplateOutletContext]="{listOfData: listOfDataActive, tabKey: tab.key}">
          </ng-container>
        </ng-container>
        <ng-container *ngIf="tab.key == 'podProblem'">
          <ng-container 
            [ngTemplateOutlet]="tplOrder" 
            [ngTemplateOutletContext]="{listOfData: listOfDataPODProblem, tabKey: tab.key}">
          </ng-container>
        </ng-container>
      </nz-tab>
      <ng-template #titleTemplate>
        <span>{{tab.name}} ({{ tab?.total }})</span>
      </ng-template>
    </ng-container>
  </nz-tabset>

  <ng-template #tplOrder let-listOfData="listOfData" let-tabKey="tabKey">
    <div *ngIf="tabKey == 'active' && numOfOrderHasInvoice">
      <div class="bottom10" style="background-color: #fffbe6; border: 1px solid #fde79b; border-radius: 3px;padding: 10px">
        <div class="flex">
          <span nz-icon nzTheme="fill" nzType="info-circle" style="color: #faad14; margin-top: 3px"></span>
          <div style="margin-left: 8px;line-height: 1.5;">We found {{ numOfOrderHasInvoice }} {{ numOfOrderHasInvoice > 1 ? 'orders have': 'order has'}} been created invoice</div>
        </div>
      </div>
    </div>
    <nz-table 
      #nzTable nzBordered="true"
      [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading"
      [nzData]="listOfData" nzSize="small"
      [nzHideOnSinglePage]="true"
      [nzPageSize]="limit"
      [nzNoResult]="tplNoData">
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <ng-template #tplNoData>
        <div class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <thead>
        <tr>
          <th nzWidth="300px">Customer Name</th>
          <th nzWidth="120px">Order ID</th>
          <th>POD status</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of nzTable.data; let i = index">
          <td>{{ item.customerName }}</td>
          <td><a [href]="getOrderLink(item)" target="_blank">{{ item.warpId }}</a></td>
          <td [ngClass]="{'warning': !!item.invoiceFileId }">
            <div *ngIf="tabKey == 'active'">
              <div *ngIf="item.invoiceFileId">
                <div>Invoice has been created</div>
                <div *ngIf="item.invoiceCreateWhen" class="grey f13" style="font-style: italic">
                  (Created {{ item.invoiceCreateWhen }})
                </div>
              </div>
              <div *ngIf="!item.invoiceFileId">
                Confirm POD ({{countConfirmedPOD(item)}}/{{countPOD(item)}})
                <span *ngIf="isConfirmedPOD(item)" style="padding-left: 5px;" nz-icon nzType="check-circle"
                  class="f16 success" nzTheme="outline" nz-tooltip nzTooltipTitle="PODs confirmed"></span>
              </div>
            </div>
            <div *ngIf="tabKey == 'podProblem'">
              <div *ngIf="item.isValidGenInvoice?.msg" class="status">
                <span nz-icon [nzType]="'close-circle'" [nzTheme]="'twotone'" [nzTwotoneColor]="'red'"></span>
                <div class="text error">{{item.isValidGenInvoice.msg}}</div>
              </div>
            </div>
          </td>
          <td>{{ item.amount }}</td>
        </tr>
      </tbody>
    </nz-table>
  </ng-template>
</div>

<div class="separator h top30"></div>
<div class="top20 flex" style="flex-direction: row-reverse;">
  <button *ngIf="selectedTabIndex == 0" nz-button
    [disabled]="!canGenerateInvoice || isDownloadingInvoice" 
    [nzLoading]="isDownloadingInvoice"
    (click)="onBtnExport()" 
    nzType="primary">Export
  </button>

  <button nz-button class="right20"
    [disabled]="isLoading || isDownloadingInvoice"
    (click)="onBtnReCheck()" 
    nzType="default">Re-Check
  </button>

</div>