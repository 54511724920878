import { Component } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { Const } from "@const/Const";
import { Utils } from "@services/utils";
import { environment } from "@env/environment";
import { DialogService } from "@dialogs/dialog.service";
import { ExportCustomerPaymentConfirm } from "./confirm-dlg";
import moment from "moment";
import { DateUtil } from "@services/date-utils";

@Component({
  selector: "ic-customer-report",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class IcCustomerPayment extends BaseComponent {
  constructor() {
    super();
  }

  public filter = {
    clientId: null,
    toDate: moment().toDate()
  }
  public loading = false;

  get needUpdate() {
    return !!this.filter.clientId;
  }

  public onBtnExport() {
    this.loading = true;
    const toDate = DateUtil.toEndOfDay(this.filter.toDate);
    const params = {
      filter: JSON.stringify({
        clientId: this.filter.clientId,
        toDate: DateUtil.convertLocalTime(toDate, 'America/New_York')?.toISOString() 
      })
    }

    let url = `${environment.backendUrl}/${Const.APIV2(Const.APIURI_FINANCES)}/reports/ic-customer-payment`;
    url = Utils.appendQueryStringIntoUrl(url, params);
    this.api.GET(url).subscribe(
      (resp) => {
        this.loading = false;
        this.openDialogConfirm(resp?.data?.list_data || []);
      },
      (err) => {
        this.showErr(err);
        this.loading = false;
      }
    );
  }

  private onRetry() {
    const toDate = DateUtil.toEndOfDay(this.filter.toDate);
    const params = {
      filter: JSON.stringify({
        clientId: this.filter.clientId,
        toDate: DateUtil.convertLocalTime(toDate, 'America/New_York')?.toISOString() 
      })
    }
    let url = `${environment.backendUrl}/${Const.APIV2(Const.APIURI_FINANCES)}/reports/ic-customer-payment`;
    url = Utils.appendQueryStringIntoUrl(url, params);
    return this.api.GET(url);
  }

  private onExport() {
    const toDate = DateUtil.toEndOfDay(this.filter.toDate);
    const params = {
      filter: JSON.stringify({
        clientId: this.filter.clientId,
        toDate: DateUtil.convertLocalTime(toDate, 'America/New_York')?.toISOString() 
      })
    }
    let url = `${environment.backendUrl}/${Const.APIV2(Const.APIURI_FINANCES)}/reports/ic-customer-payment-export`;
    url = Utils.appendQueryStringIntoUrl(url, params);
    return this.api.GET(url, { responseType: 'arraybuffer', timeout: 20 * 60 * 1000 }, 20 * 60 * 1000);
  }

  private openDialogConfirm(data) {
    DialogService.openDialog1(ExportCustomerPaymentConfirm, {
      nzMaskClosable: false,
      nzComponentParams: {
        listItems: data,
        filter: this.filter,
        onRetry: () => { return this.onRetry() },
        onExport: () => { return this.onExport() },
      },
      nzClassName: "modal-xxl",
    });
  }

}
