import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";

@Component({
  selector: '[history-request-pod]',
  templateUrl: './index.html',
  styleUrls: ['./index.scss']
})

export class HistoryRequestPod extends BaseComponent {
  @Input() data;

  getTimeRequest(item) {
    let timeRequest = item.when ?? '';
    return DateUtil.format(timeRequest, Const.FORMAT_GUI_DATETIME)
  }
}