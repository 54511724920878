<div class="container-box">
  <div class="content-box">
    <div class="upload-box">
      <div class="upload-form">
        <button nz-button (click)="onOpenUploadForm()" [disabled]="isConverting" [nzLoading]="isConverting"
          nzType="primary" nzSize="small">
          <span nz-icon nzType="upload" nzTheme="outline"></span>Upload File</button>

        <div class="group-action">
          <button *ngIf="images.length > 0" nz-button (click)="onDownloadAll()" [disabled]="isConverting"
            [nzLoading]="isConverting" nzSize="small" nz-tooltip nzTooltipTitle="Download All">
            <span nz-icon nzType="download" nzTheme="outline"></span></button>
          <button *ngIf="images.length > 0" nz-button (click)="onAnalyzeAll()" [disabled]="isConverting"
            [nzLoading]="isConverting" nzSize="small" nz-tooltip nzTooltipTitle="Analyze All">
            <span nz-icon nzType="monitor" nzTheme="outline"></span></button>
          <button *ngIf="images.length > 0" nz-button (click)="onDownloadGroupPhoto()" [disabled]="isConverting"
            [nzLoading]="isConverting" nzSize="small" nz-tooltip nzTooltipTitle="Download Group Photos">
            <span nz-icon nzType="group" nzTheme="outline"></span></button>
        </div>

        <div class="group-action">
          <button *ngIf="images.length > 0" nz-button [disabled]="isConverting" nz-popconfirm
            nzPopconfirmTitle="Are you sure you want to clear the group photos?" nzPopconfirmOkText="Yes"
            nzPopconfirmCancelText="No"
            (nzOnConfirm)="onClearGroupPhoto()" [nzLoading]="isConverting" nzSize="small" nz-tooltip
            nzTooltipTitle="Clear Group Photo">
            <span nz-icon nzType="clear" nzTheme="outline"></span></button>
        </div>

        <input type="file" id="uploadPODInput" multiple (change)="onFileSelected($event.target)"
          accept="image/jpeg,image/gif,image/png,application/pdf" />
      </div>

      <div class="preview-pages">
        <div class="preview-page"
          [ngClass]="{'selected': selectedImageIndex === index, 'assigned': image?.addedToShipments}"
          *ngFor="let image of images; let index = index;" (click)="onSelected(index, $event)">
          <div class="background-image">
            <img [src]="image.url | safeUrl" alt="" />
          </div>
          <div class="assigned-shipments" *ngIf="image?.addedToShipments">
            {{showShipmentWarpIds(image)}}</div>
          <div class="grouped" *ngIf="image.groups?.length">
            <span nz-icon nzType="plus-square" nzTheme="outline"></span>
          </div>
          <div class="page-number">{{index+1}}</div>
        </div>
      </div>
    </div>

    <div class="detail-page">
      <div class="image-preview">
        <pod-image-preview></pod-image-preview>
      </div>
      <div class="form-info" [style]="{'min-width': formInfoWidth+'%'}">
        <pod-form-info></pod-form-info>
      </div>
    </div>
  </div>
</div>