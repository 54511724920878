<div class="container-box">
  <form class="form-detail" [formGroup]="formInput" nz-form>
    <div *ngFor="let key of ['loadCode']">
      <div class="form-label-v2 bottom5">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
      <div>
        <nz-form-item>
          <nz-form-control>
            <input nz-input [formControlName]="key" 
              [placeholder]="getPlaceHolder(key)"
              (input)="onInputChanged($event, key)">
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div *ngFor="let key of ['linehaulManifestFile']" class="bottom15">
      <input #inputFile type="file" hidden accept=".csv,.xls,.xlsx" (change)="onFileSelected(key, inputFile.files)">
      <div *ngIf="!hasAttachedFile(key)" class="flex-space-between">
        <div>
          <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
          <div class="file-support-type">Supported file types: .xls, .xlxs</div>
        </div>
        <div>
          <button nz-button nzShape="round" nzType="dashed" (click)="inputFile.click()">
            <i nz-icon nzType="upload" nzTheme="outline"></i>Upload
          </button>
        </div>
      </div>
      <div *ngIf="hasAttachedFile(key)">
        <div class="form-label-v2">{{getLabel(key)}}<span *ngIf="isRequired(key)" class="label-mark-required"></span></div>
        <div class="flex-space-between" style="align-items: center;">
          <div class="file-selected">
            <i nz-icon nzType="paper-clip" nzTheme="outline"></i>{{ labelSelectFile }}
          </div>
          <button nz-button nzType="text" (click)="delFile(key, inputFile)">
            <i nz-icon nzType="delete"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="actions">
      <button nz-button [disabled]="!needUpdate" (click)="onExport()" nzType="primary" [nzLoading]="loading">Export</button>
    </div>
  </form>
</div>