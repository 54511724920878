<div class="container-box">
  <div class="search-form">
    <div class="search-box">
      <nz-input-group [nzSuffix]="suffixIconSearch" nzSize="small">
        <input type="text" nz-input placeholder="Search WARP ID, Ref#..." [(ngModel)]="searchKeyword"
          (keydown)="onSearchKeyWordChange($event)" />
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search"></span>
      </ng-template>
    </div>
  </div>
  <div class="content-box">
    <div class="table-shipment">
      <nz-table #tableShipment [nzData]="orders" nzSize="small" [nzLoading]="loading" [nzShowPagination]="false">
        <thead>
          <tr>
            <th nzWidth="100px">WARP ID</th>
            <th nzWidth="120px">Confirmed POD</th>
            <th>Ref #</th>
            <th>Pickup</th>
            <th>Dropoff</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of tableShipment.data">
            <tr>
              <td class="cell-clickable" (click)="onViewOrderDetail(data)"><span>
                  <b>{{data.orderWarpId }}</b><br />
                  ({{ data.shipmentWarpIds.join(", ") }})
                </span></td>
              <td class="text-center">{{data?.confirmedPOD}}/{{data?.totalPOD}}</td>
              <td>
                <nz-tag *ngFor="let refNum of data.refNums" nzSize="small">{{refNum}}</nz-tag>
              </td>
              <td>
                <b>{{data?.firstPickup?.locationName}}</b><br />
                {{data?.firstPickup?.address}}<br />
                <div class="time">{{data?.firstPickup?.time}}</div>
              </td>
              <td>
                <b>{{data?.lastDropoff?.locationName}}</b><br />
                {{data?.lastDropoff?.address}}<br />
                <div class="time">{{data?.lastDropoff?.time}}</div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </nz-table>
    </div>
  </div>
</div>