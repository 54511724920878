import { Component, Input } from "@angular/core";
import { BaseComponent } from "@abstract/BaseComponent";
import { QuickUploadPodService } from "../../quickUploadPod.service";
import { OrderDetailTabContext } from "../../quickUploadPod.interface";
import { Order, Shipment } from "@wearewarp/types/data-model";
import { NzNotificationDataOptions } from "ng-zorro-antd/notification";

@Component({
  selector: "pod-shipment-detail-form",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})

export class PodShipmentDetailForm extends BaseComponent {
  constructor(private quickUploadPodService: QuickUploadPodService) {
    super();
  }
  @Input() shipment: Shipment;
  @Input() showHeaderBox: boolean = true;
  public childrens: Shipment[] = [];
  public selectedShipment: Shipment;
  public isAssignPODInProcessing: boolean = false;
  public isShowActualTime: boolean = true;

  ngOnInit(): void {
    super.ngOnInit();
    this.initData();
  }

  async initData() {
    await this.getAllChildrens();
  }

  async getAllChildrens() {
    const shipmentId = this.shipment?.id;
    if (this.shipment.isCrossDock) {
      this.childrens = await this.quickUploadPodService.getAllChildrens(shipmentId);
      if (this.childrens.length > 0) {
        this.selectedShipment = JSON.parse(JSON.stringify(this.childrens[0]));
        return;
      }
    }
    this.selectedShipment = JSON.parse(JSON.stringify(this.shipment));
  }

  //params: shipment._id
  async onSelectShipment(shipmentId) {
    if (shipmentId == this.shipment._id) {
      this.selectedShipment = this.shipment;
      return;
    }
    this.selectedShipment = this.childrens.find(shipment => shipment._id == shipmentId);
  }

  async assignPOD() {
    const selectedImage = this.quickUploadPodService.getSelectedImage();
    if (!selectedImage) {
      this.showErr(`Please select an image first!`, this.getNotificationOptions());
      return;
    }
    if (!this.selectedShipment.lastJobId) {
      this.showErr(`POD cannot be added when the shipment has not been routed.`, this.getNotificationOptions());
      return;
    }

    this.confirmYesNo(`Are you sure you want to add this Photo to shipment ${this.showShipmentWarpId(this.selectedShipment.warpId)}?`, async () => {
      this.isAssignPODInProcessing = true;
      try {
        await this.quickUploadPodService.addImagesToShipment([selectedImage], this.selectedShipment);
        this.showSuccess(`Photo has been added successfully to shipment ${this.showShipmentWarpId(this.selectedShipment.warpId)}`, this.getNotificationOptions());
        this.isAssignPODInProcessing = false;

      } catch (err) {
        this.showErr(err.message, this.getNotificationOptions());
        this.isAssignPODInProcessing = false;
      }
    });
  }

  assignGroupedPOD() {
    const groupedImages = this.quickUploadPodService.getImagesByGroup();
    if (groupedImages.length == 0) {
      this.showErr(`Please add photo to group first!`, this.getNotificationOptions());
      return;
    }

    this.confirmYesNo(`Are you sure you want to add ${groupedImages.length} Photos to shipment ${this.showShipmentWarpId(this.selectedShipment.warpId)}?`, async () => {
      this.isAssignPODInProcessing = true;
      try {
        await this.quickUploadPodService.addImagesToShipment(groupedImages, this.selectedShipment);
        this.showSuccess(`Photos have been added successfully to shipment ${this.showShipmentWarpId(this.selectedShipment.warpId)}`, this.getNotificationOptions());
        this.isAssignPODInProcessing = false;
        this.quickUploadPodService.clearGroup()
      } catch (err) {
        this.showErr(err.message, this.getNotificationOptions());
        this.isAssignPODInProcessing = false;
      }
    });


  }

  getNotificationOptions(): NzNotificationDataOptions {
    let options: NzNotificationDataOptions = {
      nzPlacement: 'bottomLeft',
      nzDuration: 1500,
      nzStyle: {
        padding: '12px'
      }
    }
    return options;
  }
}
